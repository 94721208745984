@tailwind components;

@layer components{

.container-fluid {
    @apply mx-auto max-w-screen-4xl;
} 

.row {
    @apply flex flex-wrap w-full;
  }

  .col {
    @apply flex-1;
  }

  .col_1 {
    @apply w-1/12;
  }

  .col_2 {
    @apply w-2/12;
  }

  .col_3 {
    @apply w-3/12;
  }

  .col_4 {
    @apply w-4/12;
  }

  .col_5 {
    @apply w-5/12;
  }

  .col_6 {
    @apply w-6/12;
  }

  .col_7 {
    @apply w-7/12;
  }

  .col_8 {
    @apply w-8/12;
  }

  .col_9 {
    @apply w-9/12;
  }

  .col_10 {
    @apply w-10/12;
  }

  .col_11 {
    @apply w-11/12;
  }

  .col_12 {
    @apply w-full;
  }  

.advantage_grids{
    @apply container-fluid mb-[80px] md:mb-[150px] lg:mb-[150px]
}

.advantage_grid_wrapper{
    @apply gap-x-[25px] grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4
}

.preference_working{
    @apply container-fluid mb-[80px] md:mb-[150px] lg:mb-[150px]
}

.preference_working_wrapper{
    @apply gap-[25px] md:gap-[45px] lg:gap-[45px] grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 md:pr-[35px]
}

.preference_request{
    @apply container-fluid mb-[80px] md:mb-[100px] lg:mb-[100px]
}

.preference_request_head{
    @apply text-primary-blue uppercase mb-[20px]
}

.offer_grid{
    @apply grid gap-x-[25px] grid-cols-2 md:grid-cols-4 lg:grid-cols-4
}

.preference_basic{
    @apply container-fluid mb-[80px] md:mb-[150px] lg:mb-[150px]
}

/* .advantage_grid_wrapper{
    @apply flex justify-between md:justify-center lg:justify-center flex-wrap md:flex-nowrap lg:flex-nowrap
} */

.preference_basic_grids{
  @apply flex justify-between md:justify-center lg:justify-center flex-wrap md:flex-nowrap lg:flex-nowrap
}

.faq_grid_box{
    @apply mb-[56px] md:mb-[120px] lg:mb-[120px]
}

.advantage_grid{ 
    @apply relative md:mb-0 lg:mb-0 text-center rounded-[12px] border-[1px] border-[#D9D8D9] py-[12px] px-[6px] md:py-[35px] md:px-[24px] lg:py-[35px] lg:px-[24px] hover:border-[#003690]
}

.advantage_grid_img{
    @apply text-center mb-[4px] md:mb-[30px] lg:mb-[30px]
}

.advantage_content{
    @apply text-[12px] md:text-[18px] md:leading-[21px] lg:text-[18px] lg:leading-[21px] mb-[3px] md:mb-[10px] lg:mb-[10px] uppercase leading-[18px] font-suzukiBold text-primary-blue !important
}

.advantage_desc{
    @apply text-[10px] md:text-[14px] lg:text-[14px]
}

.advantage_id{
    @apply absolute left-[50%] top-[-18px] translate-x-[-50%] bg-primary-blue w-[38px] h-[38px] text-[#fff] flex justify-center items-center font-suzukiBold
}

.advantage_count{
  @apply mx-auto bg-primary-blue md:w-[50px] md:h-[50px] w-[28px] h-[28px] text-[#fff] flex justify-center items-center font-suzukiBold text-sub-heading md:text-heading 
}

.common_heading{
    @apply mb-[35px] md:mb-[90px] lg:mb-[90px] 
}

.common_heads{
    @apply mb-[12px] md:mb-[15px] lg:mb-[15px] uppercase text-primary-blue
}

.common_descs{
    @apply max-w-[80%] md:max-w-[38%] lg:max-w-[38%] text-[16px] leading-[24px]
}

}